import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import Modal from 'react-modal'
import { useMedia } from 'use-media'

// Elements
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimaryBig'
import ParseContent from 'components/shared/ParseContent'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
  useFormDuuf,
} from 'components/related/Form'

// Images
import Cancel from 'img/cancel.inline.svg'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  color: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    border-radius: unset;
    padding: 0.8rem 1rem;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonPrimaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    top: 0;
    right: 10px;
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    width: 156px;
    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    margin: 0 auto;
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.huge};
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.color.light} !important;
    @media (min-width: 576px) {
      width: 75%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(18, 21, 95, 0.5);
  }

  @media (max-width: 575px) {
    overflow-y: scroll !important;
  }
`

const StyledModal = styled(Modal)`
  background: ${({ theme }) => theme.color.primary};
  @media (min-width: 992px) {
    padding: 3rem 3rem 0rem 3rem;
    width: 1080px;
  }
  @media (max-width: 991px) {
    padding: 6rem 2rem 2rem 2rem;
    width: 95%;
  }
  @media (max-width: 575px) {
    padding: 0.5rem;
    width: 95%;
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.heading};
    font-family: ${({ theme }) => theme.font.family.secondary};
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.font.size.huge};
    }
  }

  & p {
    font-size: ${({ theme }) => theme.font.size.big};
    color: ${({ theme }) => theme.color.light};
    line-height: 30px;
  }
`

const CancelWrapper = styled.div`
  right: 26px;
  top: 22px;
  &:hover {
    cursor: pointer;
    & svg {
      opacity: 0.5;
    }
  }

  & svg {
    width: 25px;
    height: 25px;
  }
`

const Container = styled.div`
  @media (max-width: 575px) {
    padding-top: 5rem;
  }
`

interface FormLawsProps {
  isOpen: boolean
  requestClose: Function
  title: string
  // eslint-disable-next-line camelcase
  blogFields: any
  fileLink: any
}

const FormLaws: React.FC<FormLawsProps> = ({
  isOpen,
  requestClose,
  title,
  fileLink,
  blogFields,
}) => {
  const shortHeightDevice = useMedia({ maxHeight: 750 })
  const smallWidthDevice = useMedia({ maxWidth: 575 })

  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      height: shortHeightDevice || smallWidthDevice ? '90%' : 'auto',
      overflowY: shortHeightDevice || smallWidthDevice ? 'scroll' : 'auto',
      zIndex: 999,
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={requestClose}
      style={customStyles}
    >
      <CancelWrapper
        className="position-absolute"
        onClick={() => requestClose()}
      >
        <Cancel />
      </CancelWrapper>
      <Container className="container p-lg-5">
        <div className="row align-items-start mb-4">
          <div className="col-md-9">
            <ParseContent
              content="<h2>Download 'De 8 wetten van BORG-OSC'</h2>"
              className="mb-0 text-white"
            />
          </div>
        </div>
        <StyledFormDuuf
          id={8}
          generate={false}
          defaultFields={{ input_6: fileLink }}
        >
          <div className="row">
            <div className="col-sm-6">
              <FormDuufGroup hideIds={[3, 4, 6]} />
            </div>
            <div className="col-sm-6">
              <FormDuufGroup showIds={[3, 4]} />
            </div>
            <FormDuufSubmit />
          </div>
        </StyledFormDuuf>
      </Container>
    </StyledModal>
  )
}

export default FormLaws
