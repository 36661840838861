import React, { useState } from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimaryBig from 'components/elements/Buttons/ButtonPrimaryBig'
import FormLaws from 'components/flex/Form/FormLaws'

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
  margin-top: 10rem;

  & ol {
    list-style: none;
    padding-left: 0 !important;
    li {
      color: ${({ theme }) => theme.color.light};
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1400px) {
    height: 380px;
  }
`

const LeftSide = styled.div`
  margin-top: -9rem;
`

const ListIndex = styled.span`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: ${({ theme }) => theme.font.size.huge};
  color: ${({ theme }) => theme.color.secondary};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    line-height: ${({ theme }) => theme.font.size.huge} !important;
    @media (max-width: 575px) {
      line-height: ${({ theme }) => theme.font.size.quote} !important;
    }
  }
  & ol {
    & li {
      &:before {
        content: 1;
        width: 50px;
      }
    }
  }

  & h2 > span {
    @media (min-width: 992px) {
      font-size: 70px !important;
    }
  }

  & h3 {
    @media (min-width: 576px) {
      line-height: 45px !important;
    }
    @media (max-width: 575px) {
      font-size: 26px !important;
      margin-top: -1.7rem;
    }
  }
`

interface HeroWithDownloadProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Hero
}

const HeroWithDownload: React.FC<HeroWithDownloadProps> = ({ fields }) => {
  const list = fields?.list || []

  const half = Math.ceil(list.length / 2)

  const leftList = list?.slice(0, half)
  const rightList = list?.slice(-half)

  const [formOpen, setFormOpen] = useState(false)

  const handleFile = (e: any) => {
    e.preventDefault()
    setFormOpen(true)
  }

  return (
    <Wrapper className="p-lg-5 mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <LeftSide>
              <Image image={fields.image} alt="" />
              <ParseContent content={fields.descriptionleft} />
              <div className="d-flex justify-content-center mt-4">
                <ButtonPrimaryBig
                  isCustom
                  to={fields.file?.localFile?.publicURL || '/'}
                  target="_blank"
                  className="mt-5"
                >
                  <button onClick={(e) => handleFile(e)} type="button">
                    Vraag 8 wetten aan
                  </button>
                  {formOpen && (
                    <div>
                      <FormLaws
                        fileLink={fields.file?.localFile?.publicURL}
                        isOpen={formOpen}
                        title="8 Wetten van Borg OSC"
                        requestClose={() => setFormOpen(false)}
                        blogFields={fields}
                      />
                    </div>
                  )}
                </ButtonPrimaryBig>
              </div>
            </LeftSide>
          </div>
          <div className="col-lg-8">
            <Content content={fields.descriptionright} />
            <div className="row mt-5">
              <div className="col-sm-6">
                <ol>
                  {leftList?.map((item, index: number) => (
                    <li>
                      <div className="d-flex align-items-center">
                        <ListIndex>{index + 1}</ListIndex>
                        <span className="ms-3">{item?.listitem}</span>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
              <div className="col-sm-6">
                <ol>
                  {rightList?.map((item, index: number) => (
                    <li>
                      <div className="d-flex align-items-center">
                        <ListIndex>{index + half + 1}</ListIndex>
                        <span className="ms-3">{item?.listitem}</span>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default HeroWithDownload
